<template>
  <div>
    <b-card header="Add user">
      <b-form @submit="onSubmit">
        <b-alert v-if="error" show variant="danger">{{
          $t("users.error_msg")
        }}</b-alert>
        <p>{{ $t("users.create_new_link_msg") }}</p>
        <div v-if="showForm">
          <b-input
            type="email"
            v-model="mail"
            required="required"
            placeholder="Enter email"
          ></b-input>
          <b-button type="submit" @click="addUser" class="btn btn-success mt-3"
            ><i class="fas fa-link"></i> Create link</b-button
          >
          <Back-Button class="ml-2 mt-3"></Back-Button>
        </div>
        <div v-else>
          <p>
            Send this link to the new user: <strong>{{ this.link }}</strong>
          </p>
          <Back-Button class="mt-3"></Back-Button>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import BackButton from "@/components/Buttons/BackButton";

export default {
  name: "newuser",
  components: {
    BackButton
  },
  data() {
    return {
      mail: "",
      showForm: true,
      link: "",
      error: null
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
    },
    addUser() {
      this.error = null;
      if (this.mail.length > 0) {
        this.link = `https://ordernow.risa.consulting/register?email=${this.mail}`;
        this.showForm = false;
      } else {
        this.error = "missing email";
      }
    }
  }
};
</script>

<style></style>
